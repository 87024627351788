<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong ref="title" style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}
                                        {{this.typeCN == 'CN' ? '当前：销售开单浏览（出纳专用）': this.typeCN == 'FP'? '当前：销售开单浏览（发票专用）' : '当前：销售开单一览'}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="4">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 210px"
                                        v-model="pickerDate"
                                        ref="daterange"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="searchAllWarehouse()" size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openBill($refs.xGrid.getCurrentRecord())" size="mini">修改
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/lodopPrint'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="printTransferSlip($refs.xGrid.getCurrentRecord())" size="mini">查看
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button type="success" v-if="$_getActionKeyListBoolean(['/transferSlip'])" style=" margin-top: 8px; " round
                                           @click="$router.push('/transferSlip')" size="mini">新建
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/setIsBillCancel'])" style=" margin-top: 8px; " type="danger" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || this.$refs.xGrid.getCurrentRecord().is_cancel_name == '作废'"
                                           @click="setIsBillCancel($refs.xGrid.getCurrentRecord())" size="mini">作废
                                </el-button>
                                <el-checkbox style="padding-left: 7px" v-model="formData.showCancel" @change="getAllWarehouse()">显示作废</el-checkbox>
                                <el-checkbox style="padding-left: 7px" v-model="isAccurate" @change="getAllWarehouse()">精准搜索</el-checkbox>
                            </el-col>
                            <el-col :lg="12" :sm="12" :md="12">
                                <el-col class="pull-left" :lg="3">
                                    <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                                 ref="xToolbar2">
                                    </vxe-toolbar>
                                </el-col>

                                <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.CNStateManCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                    <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                    <vxe-radio-button :label="0" content="出纳未审核"></vxe-radio-button>
                                    <vxe-radio-button :label="1" content="出纳已审核"></vxe-radio-button>
                                </vxe-radio-group>
                                <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.proceedsCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                    <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                    <vxe-radio-button :label="0" content="未收款"></vxe-radio-button>
                                    <vxe-radio-button :label="1" content="已收款"></vxe-radio-button>
                                </vxe-radio-group>
                                <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.KDCCStateManCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                    <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                    <vxe-radio-button :label="0" content="出仓未审核"></vxe-radio-button>
                                    <vxe-radio-button :label="1" content="出仓已审核"></vxe-radio-button>
                                </vxe-radio-group>
                                <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.taxCheck" size="mini" style="margin-left:6px;  margin-top: 8px; " :strict="false">
                                    <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                    <vxe-radio-button :label="0" content="含税"></vxe-radio-button>
                                    <vxe-radio-button :label="1" content="不含税"></vxe-radio-button>
                                </vxe-radio-group>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <!-- 作废 @scroll ="$_scrollEvent" -->
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGridAllSalesBilling"
                                ref="xGrid"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 150)+''"
                                highlight-current-row
                                show-footer-overflow
                                show-header-overflow
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                show-footer
                                :cell-class-name="$_cellClassNameSummary"
                                @cell-dblclick="$_cellClickEventKD"
                                :mouse-config="{selected: false}"
                                :footer-method="footerMethod"
                                :row-class-name="tableRowClassName"
                                :edit-config="{showIcon:false,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #operate="{ row}">
                                <vxe-button @click="openTheWarehouse(row)">修改</vxe-button>
                                <vxe-button @click="printTransferSlip(row)">查看</vxe-button>
                            </template>
                            <!-- 经手人 -->
                            <template #select_handled_by_edit="{ row}">
                                <el-select v-model="row.handled_by" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                           size="mini"
                                           default-first-option
                                           :filter-method="$_handledByListFilter"
                                           @change="selectHandledBy({row})" clearable filterable>
                                    <el-option
                                            v-for="item in $store.getters.getHandledByList"
                                            :key="item.nick_name"
                                            :label="item.nick_name"
                                            :value="item.nick_name">
                                    </el-option>
                                </el-select>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,10000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                    <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                        <template #default>
                            <vxe-grid
                                    ref="modalGrid"
                                    show-overflow
                                    auto-resize
                                    height="300"
                                    size="mini"
                                    highlight-current-row
                                    @cell-dblclick="openReceipt($refs.modalGrid.getCurrentRecord())"
                                    :data="tableDataModel"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                isAccurate:false,
                //suggestions:[],
                showModel:{
                    bill_id:'',
                    isShow:false,
               },
                typeCN:'',//空代表默认，CN代表出纳用的（外购单一览）
                formData: {//搜索筛选
                    showCancel:false,
                    CNStateManCheck:-1,//全部 未审核 已审核
                    proceedsCheck:-1,//全部 未收款 已收款
                    KDCCStateManCheck:-1,//全部 出仓未审核 出仓已审核
                    taxCheck:-1,//全部 含税 不含税
                    name: null,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
//visible:false,
                tableColumn: [
                    {
                        field:'b_id', width: 100,visible : false,title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'clearing_form', width: 80, title: '结算方式', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tax_tate_stock', width: 65, title: '税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else{
                                return cellValue + '%'
                           }
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'settlement_days', width: 100, title: '结算天数',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'payment_days', width: 65, title: '账期',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'only_bill_date', width: 100, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'customer', width: 190, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'category', width: 55, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'stock_bill_date_dd', width: 180, title: '订单日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{
                        field:'full_thick', width: 55, title: '足厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'specification', width: 150, title: '实际规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tons_of_price', width: 80, title: '吨价', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tons_of_price_practical', width: 80, title: '实际吨价', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'batch_number', width: 140, title: '批号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'single_count', width: 55, title: '支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'warehouse_out', width: 80, title: '调出仓库', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'kdcc_state_man', width: 80, title: '出仓审核', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_kdtzs', visible: false, width: 140, title: '开单通知书-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_kd', width: 165, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_fh',visible: false, width: 120, title: '发货单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'actual_thickness', width: 60, title: '实厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'single_price', width: 80, title: '每支单价', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'weighing_heavy', width: 80, title: '过磅重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'amount_of_product', width: 80, title: '产品金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'registrar', width: 70, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'part_order', width: 70, title: '开单员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'handled_by', width: 70, title: '经手人',editRender: {autofocus: 'input.el-input__inner'}, slots: {
                            edit: 'select_handled_by_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'all_fees', width: 80, title: '合计杂费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },

                    {
                        field:'billing_company_name', width: 120, title: '开单公司', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'is_contract_fees', width: 80, title: '包费用', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'other_tax', width: 88, title: '杂费税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'pounds_fees', width: 88, title: '磅费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'wind_cut_fees', width: 88, title: '风割费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'loading_car_fees', width: 88, title: '装车费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'transfer_fees', width: 88, title: '中转费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'freight_fees', width: 88, title: '运费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{
                        field:'procedure_fees', width: 88, title: '手续费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'kick_back', width: 88, title: '佣金(回扣)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'kdcc_state_date', width: 120, title: '出仓审核时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },

                    {
                        field:'is_cancel_name', width: 55, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'actual_sales_amount', width: 105, title: '实际销售金额',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },

                    {
                        field:'print_count', width: 60, title: '打印', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                   /* {
                        field:'practical_total_price', width: 80, title: '合计实收', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },*/

                    {
                        field:'cn_state_man', width: 80, title: '出纳审核', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'cn_state_man_date', width: 170, title: '出纳审核时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'last_name_warehouse_bill', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'last_date_warehouse_bill', width: 170, title: '最后修改时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'creat_date_warehouse_bill', width: 170, title: '记录日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'practical_price', width: 80, title: '实收金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'total_amount', width: 80, title: '合计金额',visible : false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },

                    {
                        field:'theory_weight', visible : true, width: 55, title: '理计', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'remark_warehouse_bill',visible : true, width: 80, title: '订单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'remark_stock',visible : true, width: 80, title: '产品备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    /*{
                        field:'taxes_fees_second_string',visible : false, width: 100, title: '后补税金',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },*/
                    {
                        field:'taxes_fees', width: 100, title: '产品税金',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'actual_single_price', width: 105, title: '实际销售单价',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'actual_a_price', width: 105, title: '实际销售支价',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'actual_ton_price', width: 105, title: '实际销售吨价',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'naked_price', width: 100, title: '裸价',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    /*{
                        field:'naked_price_total', width: 100, title: '合计裸价',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },*/
                    {
                        field:'single_kd_cost', width: 100, title: '存货成本',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'gross_margin', width: 100, title: '毛利',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    /*{
                        field:'voucher_id', visible: false, width: 100, title: '凭证总号',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },*/
                    /*{ field:'cash_type_id_list', width: 200, title: '对应-收款单(单号)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },*/

                ],
                tableColumnCN: [
                    {type: 'seq', title: '序号', width: 50},
                    {
                        field:'payment_days', width: 65, title: '账期',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'cn_state_man', width: 80, title: '出纳审核', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'cn_state_man_date', width: 170, title: '出纳审核时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'clearing_form', width: 80, title: '结算方式', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'print_count', width: 60, title: '打印', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill',visible:true, width: 65, title: '税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else{
                                return cellValue + '%'
                           }
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill2', width: 50, title: '开票', formatter: ({cellValue}) => {
                            if (cellValue == '否') {
                                return ''
                            }else{
                                return cellValue
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'bill_date_kd', width: 100, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_kd', width: 165, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'customer', width: 240, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },  {
                        field:'total_number', width: 80, title: '合计支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'total_amount', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'practical_total_price', width: 80, title: '合计实收', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'payend_wb', width: 80, title: '已收金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'balance', width: 80, title: '未收金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    /*{
                        field:'all_fees', width: 80, title: '合计杂费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },*/
                    {
                        field:'kick_back_total', width: 80, title: '该单佣金', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'kick_back_total_payend', width: 90, title: '已支付佣金', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'kick_back_total_payend_un', width: 90, title: '未支付佣金', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{ field:'cash_type_id_list', width: 200, title: '对应-收款单(单号)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'total_weighing_heavy', width: 80, title: '总过磅重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'registrar', width: 70, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'part_order', width: 80, title: '开单员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'handled_by', width: 80, title: '经手人',editRender: {autofocus: 'input.el-input__inner'}, slots: {
                            edit: 'select_handled_by_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    /*{
                        field:'last_name_warehouse_bill', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },*/
                    {
                        field:'last_date_warehouse_bill', width: 120, title: '最后修改时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'creat_date_warehouse_bill', width: 100, title: '记录日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'order_id',visible:true, width: 100, title: '序号',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },{
                        field:'taxes_fees_second_string', width: 100, title: '后补税金',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },

                ],
                tableColumnFP: [
                    {type: 'seq', title: '序号', width: 50},
                    {
                        field:'settlement_days', width: 100, title: '结算天数',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'bill_date_kd', width: 100, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'bill_id_kd', width: 165, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'customer', width: 240, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'billing_company_name', width: 120, title: '开单公司', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'tax_tate_warehouse_bill',visible:true, width: 65, title: '税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'theory_weight', visible : true, width: 55, title: '理计', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'remark_warehouse_bill',visible : true, width: 80, title: '订单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'total_amount', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'tax_tate_warehouse_bill2', width: 60, title: '开票', formatter: ({cellValue}) => {
                            if (cellValue == '否') {
                                return ''
                            }else{
                                return cellValue
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate, this.formData)
                        }
                    },{
                        field:'has_invoice', width: 60, title: '已开', formatter: ({cellValue}) => {
                            if (cellValue == '已开') {
                                return '✔';
                            }
                        },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'theory_weight', visible : true, width: 55, title: '理计', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'invoice_remark_kd',
                        width: 200,
                        title: '单据备注',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'invoice_remark',
                        width: 200,
                        title: '发票单备注',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate, this.formData)
                        }
                    },
                    {
                        field:'practical_total_price',visible : false, width: 80, title: '合计实收', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'total_weighing_heavy',visible : false, width: 80, title: '总过磅重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'registrar', width: 70,visible : false, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'part_order', width: 80,visible : false, title: '开单员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{
                        field:'handled_by', width: 80,visible : false, title: '经手人',editRender: {autofocus: 'input.el-input__inner'}, slots: {
                            edit: 'select_handled_by_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },/*{
                        field:'last_name_warehouse_bill',visible : false, width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },*/{
                        field:'last_date_warehouse_bill',visible : false, width: 120, title: '最后修改时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'creat_date_warehouse_bill',visible : false, width: 100, title: '记录日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },{
                        field:'taxes_fees_second_string',visible : false, width: 100, title: '后补税金',slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },

                ],
                tableDataMain: [],
                tableDataModel : [],
                tableColumnModel: [
                    { field:'is_cancel', title: '状态', width: 80 , formatter: ({cellValue}) => {
                            if (cellValue){
                                return '已作废'
                           }else{
                                return '正常'
                           }
                       }
                   },
                    { field:'cash_type_id', title: '单据编码', width: 80},
                    { field:'cash_type',width: 122 , title: '单据类型'},
                    { field:'year',width: 40 , title: '年'},
                    { field:'month',width: 40 , title: '月'},
                    { field:'amount',width: 150 , title: '金额'},
                    { field:'registrar_cash_data',width: 150 , visible: false, title: '创建人'},
                    { field:'creat_date_cash_data',width: 150 ,visible: false,  title: '创建日期'},
                    { field:'last_name_cash_data',width: 150 ,visible: false,  title: '最后修改人'},
                    { field:'last_date_cash_data',width: 150 , visible: false, title: '最后修改日期'},
                ],
                filterData: {
                    "bill_id_type": "KD"
               },
                sortData: {},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
                activeName: 'first',

           }
       },
        methods: {
            openReceipt(row){
                const{ href} = this.$router.resolve({ path: '/receipt', query: { cash_type_id : row.cash_type_id,year:row.year,month:row.month,type:row.cash_type}});
                window.open(href,'_blank');
            },
            selectHandledBy({row}){
                //row.handled_by row.id
                this.$axios({
                    method:'post',
                    url:'/admin/allWarehouse/selectHandledBy',
                    params: {
                        KDid:row.bill_id_stock,
                        handled_by:row.handled_by
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '设置成功！',
                            type: 'success'
                       });
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                   }
                    this.getAllWarehouse();
               }).catch((error) =>{
                    console.log(error)
               });
                //直接提交修改结果
                //console.log(row);
           },
            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },

            findCDWBListByBillId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/allWarehouse/findCDWBListByBillId',
                    params:{
                        bill_id:this.showModel.bill_id,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataModel = response.data.list;
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'kick_back' || column.property === 'practical_price'
                            || column.property === 'single_count' || column.property === 'weighing_heavy'
                            || column.property === 'amount_of_product'
                            ||  column.property.indexOf('fees') > 0 || column.property.indexOf('payend') > 0  ||  column.property.indexOf('balance') > 0
                            || column.property === 'other_expenses_price' || column.property === 'kick_back_total'
                            || column.property === 'actual_sales_amount' || column.property === 'total_amount'
                            || column.property === 'naked_price' || column.property === 'total_weighing_heavy'
                            || column.property === 'kick_back'
                            || column.property === 'single_kd_cost' || column.property === 'gross_margin'
                        ) {
                            sums.push(this.$_sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            searchAllWarehouse() {
                this.tablePage.currentPage = 1;
                this.getAllWarehouse();
           },
            getAllWarehouse() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let searchUrl = '/admin/allWarehouse/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                        formData:this.formData,
                        isAccurate:this.isAccurate,
                        typeCN:this.typeCN
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        //this.suggestions = []
                        //this.$refs.daterange.click();
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllWarehouse();
           },
            openTheWarehouse(row) {
                this.$router.push({path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd}})
           },
            printTransferSlip(row) {
                //this.$router.push({path: '/printTransferSlip', query: {bill_id_kd : row.bill_id_kd}})
                const{ href} = this.$router.resolve({path: '/printTransferSlip', query: {bill_id_kd : row.bill_id_kd}});
                window.open(href,'_blank');
           },

            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },

       },computed: {
       },
        created() {
            this.$nextTick(() => {
                if (!this.isBlankVue(this.$route.query.typeCN)){
                    this.typeCN = this.$route.query.typeCN;
                    if(this.typeCN == 'CN'){
                        this.filterData = {"bill_id_type":"KD"}
                        this.tableColumn = this.tableColumnCN;
                    }else if(this.typeCN == 'FP'){
                        this.tableColumn = this.tableColumnFP;
                    }
                }else{
                    this.sortData = {
                        only_bill_date: "desc",
                        b_id: "desc",
                        order_id: "asc",
                    }
                }

                if (!this.isBlankVue(this.$route.query.pickerDate)){
                    this.pickerDate = this.$route.query.pickerDate
                    this.filterData.bill_id_type = 'KD'
                    this.filterData.specification = this.$route.query.specification
                    this.filterData.category = this.$route.query.category
                    this.filterData.sales_man = this.$route.query.sales_man
                    this.filterData.customer = this.$route.query.customer
                    this.filterData.voucher_id = this.$route.query.voucher_id
                    this.isAccurate = true
                }
                this.$_getAccountListByRoleId('16');//经手人
                this.getAllWarehouse();
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.$store.getters.getLoginAccount.is_show_company_name){
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('customer'))
                }
                if (this.filterData.bill_id_type == 'KD' && this.$_getActionKeyListBoolean(['common/hasLookKdCost'])){
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('single_kd_cost'))
                    this.$refs.xGrid.showColumn(this.$refs.xGrid.getColumnByField('gross_margin'))
                }else{
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('single_kd_cost'))
                    this.$refs.xGrid.hideColumn(this.$refs.xGrid.getColumnByField('gross_margin'))
                }
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }
    /*头部字段过多异常修复 start*/
    /deep/ .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis>.vxe-cell {
        max-height: initial !important;
    }
    /deep/ .vxe-header--column .vxe-cell--title{
        line-height: 2;
    }
    /*头部字段过多异常修复 end*/
</style>
